<template>
    <div class="markThree">
        <img src="http://iv.vu818.com/img/bg1.jpg" alt="" class="mtBg" />
        <div class="mkTBox">
            <!-- 左边图片 -->
            <div class="mkTLeft wow animate__animated animate__slideInLeft">
                <img src="https://iv.vu818.com/img/img2.png" alt="" class="mkTImg" />
            </div>
            <!-- 右边文字 -->
            <div class="mkTRight wow animate__animated animate__slideInRight">
                <div class="mkTBig">单独手机站/小程序的缺陷</div>
                <div class="mktSmall">
                    <div class="mkTP">
                        <div>
                            <img src="https://iv.vu818.com/img/vu818.png" alt="" />
                        </div>
                        <span>由于与PC官网信息不互通，信息缺乏审核、权威性不足</span>
                    </div>
                    <div class="mkTP">
                        <div>
                            <img src="https://iv.vu818.com/img/vu818.png" alt="" />
                        </div>
                        <span>内容随意会导致企业形象受损</span>
                    </div>
                    <div class="mkTP">
                        <div>
                            <img src="https://iv.vu818.com/img/vu818.png" alt="" />
                        </div>
                        <span>VI及传播理念不一致，产生品牌认知困惑</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MarkThree',
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: 'wow',
            animateClass: 'animate__animated',
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.markThree {
    width: 100%;
    height: 800px;
    margin-top: 50px;
    position: relative;
    text-align: left;
    .mtBg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0px;
    }
    .mkTBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        // 左边图片
        .mkTLeft {
            width: 40%;
            height: 80%;
            padding-top: 10%;
            float: left;
            text-align: center;
        }
        // 右边文字
        .mkTRight {
            width: 50%;
            height: 50%;
            float: right;
            margin-top: 250px;
            .mkTBig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 36px;
                font-weight: 900;
            }
            .mktSmall {
                width: 100%;
                height: 150px;
                margin-top: 50px;
                .mkTP {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    font-size: 20px;
                    margin-top: 10px;
                    position: relative;
                    & > div {
                        width: 25px;
                        height: 25px;
                        overflow: hidden;
                        position: absolute;
                        top: 5px;
                        left: -33px;
                        & > img {
                            position: absolute;
                            left: -460px;
                            top: -986px;
                        }
                    }
                }
            }
        }
    }
}
</style>
