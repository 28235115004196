<template>
    <div class="markTwo">
        <div class="mtBox">
            <!-- 标题 -->
            <div class="mtTitle wow animate__animated animate__slideInUp">全网型官网好处是什么</div>
            <!-- 图片 -->
            <div class="mtBigImg">
                <img class="mtbImg wow animate__animated animate__fadeInUp" src="https://iv.vu818.com/img/img1.jpg" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MarkTwo',
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: 'wow',
            animateClass: 'animate__animated',
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.markTwo {
    width: 100%;
    height: 800px;
    margin-top: 20px;
    .mtBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
    }
    // 标题
    .mtTitle {
        width: 30%;
        height: 50px;
        margin: 0 auto;
        margin-top: 50px;
        line-height: 50px;
        text-align: center;
        text-align: center;
        font-size: 36px;
    }
    // 图片
    .mtBigImg {
        width: 100%;
        height: 400px;
        margin-top: 70px;
    }
}
</style>
